import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { WhoMatches } from './whomatches';
import { NotfoundPage } from './notfoundPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/:id">
          <WhoMatches />
        </Route>

        <Route>
          <NotfoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
