import styled from 'styled-components';

type MusicBoxProps = {
  imgUrl?: string;
};

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #202020;
  overflow-x: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  progress.progress-bar-topartists {
    height: 2px;
    width: 100%;
    margin-left: 5px;

    &::-webkit-progress-bar {
      background-color: #383737;
      height: 2px;
    }
    &::-webkit-progress-value {
      background-color: #0d65ff;
      height: 2px;
    }
  }

  button.btn--remove-selected {
    svg {
      path {
        stroke: #8f8f8f;
      }
    }
  }

  img {
    width: 70%;
    max-width: 250px;
  }

  span.guide--name {
    width: 90%;
    max-width: 220px;
    font-size: 1.4rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    color: #cfcfcf;
  }

  span.songwriters--name {
    width: 90%;
    max-width: 220px;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #8f8f8f;
  }

  span.guide--question {
    width: 90%;
    max-width: 320px;
    font-size: 1.2rem;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    color: white;
  }

  span.time-to-finish {
    font-size: 1.4rem;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    color: #ef233c;
    margin-top: 15px;
  }

  span.success--main {
    font-style: normal;
    font-weight: normal;
    color: #fff;
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
  }

  a.download--app,
  button.download--app {
    padding: 12px 60px;
    color: #fff;
    background-color: #0d65ff;
    font-size: 1rem;
    border-radius: 30px;

    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
  }
  button.share--app {
    padding: 12px 10px;
    color: #fff;
    border: 1px #8f8f8f solid;
    font-size: 1rem;
    border-radius: 30px;

    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
  }

  button.share--with--friends--app {
    padding: 12px 80px;
    color: #fff;
    border: 1px #8f8f8f solid;
    font-size: 1rem;
    border-radius: 30px;

    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
  }

  button.share--who-matches {
    padding: 12px 30px;
    color: #fff;
    background-color: #ef233c;
    font-size: 1.1rem;
    border-radius: 30px;
  }

  button.download--app:disabled {
    background-color: #504d4d;
    color: #8f8f8f;
    cursor: not-allowed;
  }

  span.success--desc {
    font-style: normal;
    font-weight: normal;
    color: #fff;
    font-size: 16px;
    margin-top: 30px;
    width: 60%;
    text-align: center;
  }

  div.player--box {
    width: 80%;
    max-width: 450px;

    div.rhap_time {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #8f8f8f;
    }

    div.rhap_container {
      border-radius: 8px;
      box-shadow: none;
      background-color: #202020;

      div.rhap_progress-section {
        div.rhap_progress-container {
          div.rhap_progress-indicator {
            visibility: hidden;
          }

          div.rhap_download-progress {
            background: #383737;
          }

          div.rhap_progress-filled {
            background: linear-gradient(270.01deg, #0d65ff 0.01%, rgba(13, 94, 255, 0) 100%);
            box-shadow: 0px 0px 10px #0d65ff;
          }
        }
      }

      div.rhap_controls-section {
        flex: 0;
        margin: 0;

        div.rhap_main-controls {
          button {
            margin: 0;
            width: 24px;
          }
        }
      }

      svg {
        margin: none !important;
        margin-right: none !important;
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const MusicBox = styled.button`
  width: 96px;
  height: 96px;
  min-height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-image: url(${(props: MusicBoxProps) => props.imgUrl || ''});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 30px 0 20px 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    width: 110px;
    height: 110px;
  }

  img {
    filter: brightness(0) invert(1);
    margin: 0 auto;
    width: 40%;
  }
`;

export const VoteButton = styled.button`
  padding: 10px 18px;
  color: white;
  max-width: 180px;
  height: 80px;
  border: 1px solid white;
  border-radius: 30px;
`;
