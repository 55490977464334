import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useParams } from 'react-router-dom';

import { Container } from '../../poll/Pages/styles';

import mpLogo from '../../images/logo-musicplayce-white.svg';
import emojiError from '../../images/emoji-error-pv.png';

import avatar from '../../images/avatar.svg';
import avatar2 from '../../images/avatar2.svg';
import avatar3 from '../../images/avatar3.svg';
import avatar4 from '../../images/avatar4.svg';

type Props = {
  errorType?: string;
};

type routeParams = {
  id: string;
};

const WhoMatchesError: React.FC<Props> = ({ errorType }) => {
  const { id }: routeParams = useParams();

  const intl = useIntl();

  let errorMsg = intl.formatMessage({
    id: 'poll.errors.unknown-error',
    defaultMessage: 'Erro desconhecido'
  });

  if (errorType === 'undefined_error') {
    errorMsg = intl.formatMessage({
      id: 'whomatches.errors.invalid-whomatches',
      defaultMessage: 'Com quem combina inválido'
    });
  }

  if (errorType === 'already-done-indication') {
    errorMsg = intl.formatMessage({
      id: 'whomatches.errors.already-done-indication',
      defaultMessage: 'Você já fez essa indicação hoje'
    });
  }

  if (errorType === 'global/not-found') {
    errorMsg = intl.formatMessage({
      id: 'whomatches.errors.not-found',
      defaultMessage: 'Com quem combina não encontrado'
    });
  }

  if (errorType === 'global/unknown') {
    errorMsg = intl.formatMessage({
      id: 'whomatches.errors.whomatches-expired',
      defaultMessage: 'Ops, Com quem combina encerrado!'
    });
  }

  const clearIndication = () => {
    window.location.reload();
  };

  return (
    <Container>
      {errorType === 'global/unknown' ? (
        <>
          <img src={mpLogo} alt="MusicPlayce Logo" className="py-10" />

          <img src={emojiError} alt="Error emoji" style={{ width: 130 }} className="py-10" />
          <span className="success--main">
            <FormattedMessage id="poll.errors.expired-poll" defaultMessage="Ops, Enquete encerrada!" />
          </span>

          <a className="download--app" href="http://link.musicplayce.com/baixar">
            <FormattedMessage id="poll.success.download-app" defaultMessage="Baixe o App" />
          </a>

          <span className="success--desc">
            <FormattedMessage
              id="poll.success.minimun-desc"
              defaultMessage="e participe da carreira de outros artistas"
            />
          </span>
          <div className="flex mt-4 items-center justify-around">
            <img src={avatar} alt="avatar" />
            <img src={avatar2} alt="avatar-2" />
            <img src={avatar3} alt="avatar-3" />
            <img src={avatar4} alt="avatar-4" />
          </div>
        </>
      ) : (
        <>
          <img src={mpLogo} alt="MusicPlayce Logo" className="py-10" />

          <img src={emojiError} alt="Error emoji" style={{ width: 130 }} className="py-10" />
          <span className="success--main">{errorMsg}</span>

          <div className="flex flex-col">
            <a className="download--app text-center" href="http://link.musicplayce.com/baixar">
              <FormattedMessage id="poll.success.download-app" defaultMessage="Baixe o App" />
            </a>

            {errorType === 'already-done-indication' && (
              <button className="download--app mt-4" type="button" onClick={clearIndication}>
                <FormattedMessage id="whomatches.indication.button-again" defaultMessage="Fazer outra indicação" />
              </button>
            )}
          </div>

          <span className="success--desc">
            <FormattedMessage
              id="poll.success.minimun-desc"
              defaultMessage="e participe da carreira de outros artistas"
            />
          </span>
          <div className="flex mt-4 items-center justify-around">
            <img src={avatar} alt="avatar" />
            <img src={avatar2} alt="avatar-2" />
            <img src={avatar3} alt="avatar-3" />
            <img src={avatar4} alt="avatar-4" />
          </div>
        </>
      )}
    </Container>
  );
};

export default WhoMatchesError;
