import firebase from 'firebase';

const devConfig = {
  apiKey: 'AIzaSyDhGt62kxajvSG6ObL8zsu-zZcPn4-r20w',
  authDomain: 'musicplayce-dev-65d73.firebaseapp.com',
  projectId: 'musicplayce-dev-65d73',
  storageBucket: 'musicplayce-dev-65d73.appspot.com',
  messagingSenderId: '230703583679',
  appId: '1:230703583679:web:ae26ee955758c512be910a',
  measurementId: 'G-5T0W64521L'
};

const hmlConfig = {
  apiKey: 'AIzaSyBHDuqX4Tb_H4SurfeGD6aBTtCE1N0XaSM',
  authDomain: 'musicplayce-hml.firebaseapp.com',
  projectId: 'musicplayce-hml',
  storageBucket: 'musicplayce-hml.appspot.com',
  messagingSenderId: '974934384667',
  appId: '1:974934384667:web:53c1fde195a4686c7658be',
  measurementId: 'G-EHHHBGNDLD'
};

const prodConfig = {
  apiKey: 'AIzaSyCpTP-_eZjRthBwNPjxZE7o8m07w4qD6tg',
  authDomain: 'musicplayce-prod.firebaseapp.com',
  projectId: 'musicplayce-prod',
  storageBucket: 'musicplayce-prod.appspot.com',
  messagingSenderId: '581502747976',
  appId: '1:581502747976:web:7c825d6fd2f0e8e05b1f7d',
  measurementId: 'G-N8908XKS5Q'
};

console.log(`env: ${process.env.REACT_APP_NODE_ENV}`);
// NOTE -> inicializando o firebase e o remoteConfig
let app;
if (process.env.REACT_APP_NODE_ENV === 'prod') {
  firebase.initializeApp(prodConfig);
} else if (process.env.REACT_APP_NODE_ENV === 'hml') {
  firebase.initializeApp(hmlConfig);
} else if (process.env.REACT_APP_NODE_ENV === 'dev') {
  firebase.initializeApp(devConfig);
} else {
  firebase.initializeApp(prodConfig);
}

export default firebase;
