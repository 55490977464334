import React, { memo } from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

type Props = {
  isLoading: boolean;
};

const Spinner: React.FC<Props> = ({ isLoading, ...rest }: Props) => {
  return <SyncLoader size={6} margin={2} color="#EF233C" loading={isLoading} {...rest} />;
};

export default memo(Spinner);
