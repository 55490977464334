import firebase from '../../firebaseConfig';
import { Composition, IndicationRelevancesValues, Score, User } from '../../interfaces';

// TODO -> tirar os logs dps que o vitor ver

export const listenedScoreCalculation = (
  percentListened: number,
  listRelevancesValues: IndicationRelevancesValues[]
) => {
  // eslint-disable-next-line no-var
  var i: number;
  let userListenedScore: any;
  let listIndicationRelevancesValues: IndicationRelevancesValues[];
  let listenedKeys;
  // eslint-disable-next-line no-var

  const percent = percentListened * 100;

  if (listRelevancesValues !== null) {
    listIndicationRelevancesValues = listRelevancesValues.filter(
      (element: IndicationRelevancesValues) => element.key === 'listened'
    );

    const indicationRelevancesValues: IndicationRelevancesValues = listIndicationRelevancesValues[0];

    const listenedRelevancesValues = Object.entries(indicationRelevancesValues.value);

    // eslint-disable-next-line no-var
    for (i = 0; i < listenedRelevancesValues.length; i++) {
      if (i === listenedRelevancesValues.length - 1) {
        const lastIndex = parseInt(listenedRelevancesValues[i][0], 10);

        if (percent >= lastIndex) {
          // eslint-disable-next-line prefer-destructuring
          userListenedScore = listenedRelevancesValues[i][1];

          // eslint-disable-next-line prefer-destructuring
          listenedKeys = listenedRelevancesValues[i][0];
        }
      } else {
        const firstIndex = parseInt(listenedRelevancesValues[i][0], 10);

        const secondIndex = parseInt(listenedRelevancesValues[i + 1][0], 10);

        if (percent > firstIndex && percent < secondIndex) {
          // eslint-disable-next-line prefer-destructuring
          userListenedScore = listenedRelevancesValues[i][1];

          // eslint-disable-next-line prefer-destructuring
          listenedKeys = listenedRelevancesValues[i][0];
        }
      }
    }
  }

  return { userListenedScore, listenedKeys };
};

export const matchGenresScoreCalculation = (
  listRelevancesValues: IndicationRelevancesValues[],
  selectedUser: User,
  composition: Composition
) => {
  // eslint-disable-next-line no-var
  var i: number;
  let contains: boolean;
  let matchGenresScore: any;
  let listGenresRelevanceValue: IndicationRelevancesValues[];
  // eslint-disable-next-line no-var

  if (listRelevancesValues !== null) {
    listGenresRelevanceValue = listRelevancesValues.filter(
      (element: IndicationRelevancesValues) => element.key === 'matchGenres'
    );

    const genresRelevanceValue: IndicationRelevancesValues = listGenresRelevanceValue[0].value;

    if (selectedUser) {
      if (selectedUser?.genres) {
        if (composition?.genres) {
          contains = composition.genres.some(
            (element) => selectedUser.genres.includes(element) || selectedUser.genres!.includes(element.toLowerCase())
          );

          if (contains) {
            matchGenresScore = genresRelevanceValue;
          }
        }
      } else {
        matchGenresScore = genresRelevanceValue;
      }
    }
  }

  return matchGenresScore;
};

export const getIndicationRelevancesValue = async () => {
  const remoteConfig = firebase.remoteConfig();

  const list: IndicationRelevancesValues[] = [];

  try {
    // TODO -> Mudar tempo de mudança de remote, se necessário

    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    remoteConfig.settings.fetchTimeoutMillis = 15000;

    await remoteConfig.fetchAndActivate();

    const value = remoteConfig.getValue('indicationRelevancesValues');

    const valueObject = JSON.parse(value.asString());

    valueObject.forEach((element: IndicationRelevancesValues) => {
      list.push(element);
    });
  } catch (err) {
    console.log(err);
  }

  return list;
};
