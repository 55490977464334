import firebase from '../../firebaseConfig';

const getPoll = async (compositionId: string) => {
  const db = firebase.firestore();

  const data = await db.collection('compositions').doc(compositionId).get();

  if (data.exists && data?.data()?.deleted === false) {
    return { success: true, data: data.data() };
  }

  return {
    success: false,
    code: 'undefined_error',
    message: 'undefined_error'
  };
};

export default getPoll;
