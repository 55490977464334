import firebase from '../../firebaseConfig';

let hasSent = false;

const playService = async (pollId: string, total_listen_time?: number) => {
  const play = {
    compositionId: pollId,
    userProfile: {
      id: '',
      name: '',
      avatarUrl: ''
    },
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    createdAt: firebase.firestore.FieldValue.serverTimestamp()
  };

  const db = firebase.firestore();

  const batch = db.batch();

  try {
    if (!hasSent) {
      hasSent = true;
      console.log(pollId);
      const compositionRef = db.collection('compositions').doc(pollId);
      batch.update(compositionRef, { playsCount: firebase.firestore.FieldValue.increment(1) });

      const playRef = db.collection(`compositions/${pollId}/plays`).doc();
      batch.set(playRef, play);

      console.log('teste play');

      await batch.commit();

      return;
    }
    return;
  } catch (err) {
    hasSent = false;
    return {
      success: false,
      code: 'undefined_error',
      message: 'undefined_error'
    };
  }
};

export default playService;
