import firebase from '../../firebaseConfig';
import { User } from '../../interfaces';

const searchService = async (query: string) => {
  const userList: User[] = [];

  const db = firebase.firestore();

  const data = await db
    .collection('userProfiles')
    .orderBy('nameSearch')
    .where('type', 'array-contains', 'ARTIST')
    .startAt(query)
    .endAt(query + '\uf8ff')
    .limit(4)
    .get();

  if (data.docs) {
    Object.keys(data.docs).forEach((key: any) => {
      const user = data.docs[key].data() as User;
      user.id = data.docs[key].id;
      return userList.push(user);
    });
  }

  if (!data.empty) {
    return { success: true, data: userList };
  }

  return {
    success: false,
    code: 'undefined_error',
    message: 'undefined_error'
  };
};

export default searchService;
