import React, { ChangeEvent, useCallback, useEffect, useRef, useState, useContext } from 'react';
import publicIp from 'public-ip';
import _ from 'lodash';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import useEventListener from '@use-it/event-listener';
import { GrClose } from 'react-icons/gr';
import { TiMediaPauseOutline, TiMediaPlayOutline } from 'react-icons/ti';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import 'react-h5-audio-player/lib/styles.css';

import Spinner from '../../components/Spinner/Spinner';
import { Composition, User, Indication, TopArtist, IndicationRelevancesValues, Score } from '../../interfaces';
import firebase from '../../firebaseConfig';

import { Container } from '../../poll/Pages/styles';
import {
  IndicationInput,
  ResultContainer,
  ResultsBox,
  SelectedArtistPhoto,
  fallbackUserImg,
  SmallUserPhoto
} from './styles';

import getPollService from '../../poll/Services/getPollService';
import playService from '../../poll/Services/playService';
import searchService from '../Services/searchService';
import indicationService, {
  verifyIndication,
  getIndicationArtistCount,
  getTopArtistsIndicatedByComposition
} from '../Services/indicationService';
import WhoMatchesError from './WhoMatchesError';
import {
  getIndicationRelevancesValue,
  listenedScoreCalculation,
  matchGenresScoreCalculation
} from '../Services/indicationScoreService';

type routeParams = {
  id: string;
};

export const getClientIp = async () => {
  return publicIp.v4({
    fallbackUrls: ['https://ifconfig.co/ip']
  });
};

export const WhoMatches: React.FC = () => {
  const intl = useIntl();
  const playerRef = useRef<HTMLAudioElement | any>(null);
  const player = document.getElementsByTagName('audio')[0];

  const [whomatchesData, setWhomatchesData] = useState<Composition | any>();
  const [isIndicationLoading, setIsIndicationLoading] = useState(false);
  const [userLocation, setUserLocation] = useState<Geolocation | any>({});
  const [artistPorcentageRaw, setArtistPorcentageRaw] = useState(0);
  const [topArtistsIndicated, setTopArtistsIndicated] = useState<TopArtist[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchResult, setSearchResult] = useState<User[] | [] | any>();
  const [hasError, setHasError] = useState(false);
  const [errorCode, setErrorCode] = useState<string | undefined>('');
  const [selectedUser, setSelectedUser] = useState<User | any>();

  // Relevancia
  const [seconds, setSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [percent, setPercent] = useState(0);
  const [start, setStart] = useState(false);

  const [list, setList] = useState<IndicationRelevancesValues[]>([]);
  const [score, setScore] = useState<Score>({} as Score);

  const [inputValue, setInputValue] = useState('');

  const { id }: routeParams = useParams();

  const updateQuery = () => {
    // A search query api call.
    setSearchLoading(true);
    searchService(inputValue.toLowerCase().trim()).then((res) => {
      setSearchResult([]);
      if (!res.success) {
        setSearchLoading(false);
        return;
      }
      setSearchLoading(false);
      setSearchResult(res.data);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(_.debounce(updateQuery, 500), [inputValue]);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      });
    } else {
      setUserLocation({
        latitude: 0.0,
        longitude: 0.0
      });
    }
  }

  // get whomatches
  useEffect(() => {
    getLocation();
    setLoading(true);
    getPollService(id).then((res) => {
      setLoading(false);
      if (!res.success) {
        setHasError(true);
        return setErrorCode(res.code);
      }
      setWhomatchesData(res?.data);
      document.title = `Com quem combina? | ${res?.data?.title || ''} | MusicPlayce`;
    });

    handleGetIndication();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // exec function on debounce
  useEffect(() => {
    if (inputValue) {
      delayedQuery();
    }

    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [inputValue, delayedQuery]);

  // Relevancia

  const handleGetIndication = async () => {
    const newList = await getIndicationRelevancesValue();

    setList(newList);
  };

  const handleScore = () => {
    let totalIndicationScore = 0;
    score.matchs = [];

    const matchGenres = matchGenresScoreCalculation(list, selectedUser, whomatchesData);
    const { userListenedScore, listenedKeys } = listenedScoreCalculation(percent, list);

    if (listenedKeys && userListenedScore) {
      score.matchs.push(`listened_${listenedKeys}`);
      totalIndicationScore += userListenedScore;
    }
    if (matchGenres) {
      score.matchs.push('matchGenres');
      totalIndicationScore += matchGenres;
    }

    score.percentTotalScore = totalIndicationScore;
  };

  // NOTE -> Chama para setar segundos ouvido e a duração da guia

  const handleListen = () => {
    setDuration(playerRef?.current?.audio?.current?.duration);

    if (percent > 1) {
      setSeconds(seconds);
      setStart(false);
    } else if (percent > 0.9) {
      setPercent(1);
    } else {
      setPercent(seconds / duration);
    }
  };

  const handleStartAudio = () => {
    setStart(true);
  };

  const handleStopAudio = () => {
    setStart(false);
  };

  useEffect(() => {
    let interval: any;

    if (start) {
      interval = setInterval(() => {
        setSeconds((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [start]);

  const clearIndication = () => {
    window.location.reload();
  };

  const refTotalListenTimeLastUpdate = useRef(0);
  const refTotalListenTime = useRef(0);

  let isPlayPosted = false;

  const getIndicationArtist = () => {
    getIndicationArtistCount(id, selectedUser?.id).then((value) => {
      const indicationsCountPlus = whomatchesData?.indicationsCount + 1;
      setArtistPorcentageRaw(
        whomatchesData?.indicationsCount === 0 ? 100 : Math.trunc((value * 100) / indicationsCountPlus)
      );
    });
  };

  const getTopFive = () => {
    const topartistsParsed: TopArtist[] = [];

    getTopArtistsIndicatedByComposition(id).then((value) => {
      const indicationsCountPlus = whomatchesData?.indicationsCount + 1;
      if (value.success) {
        value.data?.map((item) => {
          item.percent =
            whomatchesData?.indicationsCount === 0
              ? 100
              : Math.trunc((item?.indicationsCount * 100) / indicationsCountPlus);
          topartistsParsed.push(item);
        });

        topartistsParsed.sort((a, b) => b.percent - a.percent);
        setTopArtistsIndicated([...topartistsParsed]);
      }
    });
  };

  const handleIndication = async () => {
    setIsIndicationLoading(true);
    const userIp = await getClientIp();

    // NOTE -> busco aqui as informações de score
    handleScore();

    const indication: Indication = {
      compositionId: id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      location: {
        geolocation: userLocation
      },
      userProfileFrom: {
        id: userIp
      },
      userProfileTo: {
        id: selectedUser?.id,
        name: selectedUser?.name,
        avatarUrl: selectedUser?.avatarUrl
      },
      score
    };

    verifyIndication(id, selectedUser?.id, userIp).then((value) => {
      if (value) {
        setHasError(true);
        return setErrorCode('already-done-indication');
      }
      indicationService(indication).then((res) => {
        if (!res.success) {
          setHasError(true);
          return setErrorCode(res.code);
        }

        getIndicationArtist();
        getTopFive();

        setIsIndicationLoading(false);
        setSuccess(true);
      });
    });
  };

  const sendPlayRequest = async () => {
    if (refTotalListenTimeLastUpdate.current > 0) {
      // how many seconds passed since last call
      const delta = new Date().getTime() - refTotalListenTimeLastUpdate.current;
      /**
       * if more than 1 second has passed, maybe the user has paused and played again
       * we only count reasonably amount of time to ensure the user was listening
       */
      if (delta < 1000) {
        refTotalListenTime.current += delta / 1000;
      }
    }

    refTotalListenTimeLastUpdate.current = new Date().getTime();

    const playerCurrentTime = playerRef?.current?.audio?.current?.currentTime;

    if (playerCurrentTime && playerCurrentTime > 10 && !isPlayPosted) {
      await playService(id, Math.floor(refTotalListenTime.current));

      isPlayPosted = true;
    }
  };

  useEventListener('timeupdate', sendPlayRequest, player);

  const hasResults = inputValue && searchResult?.length;
  const noResults = inputValue && !searchResult?.length;

  let songWritersName = '';

  if (whomatchesData?.songwritersIds && whomatchesData?.songwritersIds?.length > 1 && whomatchesData?.userProfileName) {
    songWritersName = `${whomatchesData?.userProfileName}, ${intl.formatMessage({
      id: 'plus',
      defaultMessage: 'mais'
    })} ${whomatchesData?.songwritersIds?.length - 1}`;
  } else if (
    whomatchesData?.songwritersIds &&
    whomatchesData?.songwritersIds?.length === 1 &&
    whomatchesData?.userProfileName
  ) {
    songWritersName = `${whomatchesData?.userProfileName}`;
  }

  const shareGuide = async () => {
    if (navigator.share) {
      await navigator
        .share({
          url: `https://cqc.musicplayce.com/${id}`,
          text: intl.formatMessage({
            id: 'whomatches.indication.share_text',
            defaultMessage:
              'Você gosta mesmo de música? Qual artista você imagina gravando essa composição? Ouça e indique com quem combina!'
          }),
          title: whomatchesData?.title
        })
        .then(() => {
          console.log('Sharing successfull');
        })
        .catch(() => {
          console.log('Sharing failed');
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Spinner isLoading />
        </Container>
      ) : (
        <>
          {hasError || !id ? (
            <WhoMatchesError errorType={!id ? 'global/not-found' : errorCode} />
          ) : (
            <Container>
              <span className="guide--name mt-8">{whomatchesData?.title}</span>
              <span className="songwriters--name">{songWritersName}</span>

              <div className="player--box">
                <AudioPlayer
                  onListen={(e) => {
                    handleListen();
                  }}
                  onPlay={(e) => {
                    handleStartAudio();
                  }}
                  onPause={(e) => {
                    handleStopAudio();
                  }}
                  src={whomatchesData?.audioUrl}
                  customIcons={{
                    play: <TiMediaPlayOutline size={24} />,
                    pause: <TiMediaPauseOutline size={24} />
                  }}
                  customProgressBarSection={[
                    RHAP_UI.CURRENT_TIME,
                    <span style={{ color: '#8F8F8F', fontSize: 12, margin: '0 5px' }}> / </span>,
                    RHAP_UI.DURATION,
                    RHAP_UI.PROGRESS_BAR
                  ]}
                  customVolumeControls={[]}
                  customAdditionalControls={[]}
                  layout="horizontal"
                  showJumpControls={false}
                  autoPlay={false}
                  ref={playerRef}
                />
              </div>

              <SelectedArtistPhoto imgUrl={selectedUser?.avatarUrl || undefined} />

              {success ? (
                <>
                  <span className="text-center text-lg text-white font-semibold" style={{ marginTop: 24 }}>
                    {selectedUser?.name}
                  </span>
                  <span className="text-center text-sm" style={{ color: '#8F8F8F', maxWidth: 200 }}>
                    <FormattedMessage
                      id="whomatches.success.is-your-indication"
                      values={{ guideName: whomatchesData?.title }}
                    />
                  </span>
                  <span className="text-center text-3xl text-white font-semibold" style={{ marginTop: 24 }}>
                    {`${artistPorcentageRaw || 0}%`}
                  </span>
                  <span className="text-center text-sm" style={{ color: '#8F8F8F', maxWidth: 200 }}>
                    <FormattedMessage id="whomatches.success.people-agree-with-you" />
                  </span>
                  <div style={{ width: '100%', maxWidth: 480, height: 1, backgroundColor: '#2E2E2E', marginTop: 24 }} />
                  <span className="text-center text-sm" style={{ color: '#fff', marginTop: 24 }}>
                    <FormattedMessage
                      id="whomatches.success.top-indicated"
                      values={{ indicatedNumbers: topArtistsIndicated.length || 5 }}
                    />
                  </span>
                  <div className="flex flex-col px-8 sm:px-0" style={{ width: '100%', maxWidth: 480, marginTop: 24 }}>
                    {topArtistsIndicated.map((artist, index) => (
                      <div key={index} className="flex items-center align-center my-2">
                        <SmallUserPhoto
                          key={index}
                          isSelected={index === 0}
                          imgUrl={artist?.userProfile?.avatarUrl || undefined}
                          isSmall
                        />
                        <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <span style={{ color: '#CFCFCF', fontSize: 14, marginLeft: 5 }}>
                              {artist?.userProfile?.name}
                            </span>
                            <span style={{ color: index === 0 ? '#0D65FF' : '#CFCFCF', fontSize: 14 }}>
                              {artist?.percent}%
                            </span>
                          </div>
                          <progress value={artist?.percent} max={100} className="progress-bar-topartists" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <button className="download--app flex mt-10 items-center" type="button" onClick={clearIndication}>
                    <FormattedMessage id="whomatches.indication.button-again" defaultMessage="Fazer outra indicação" />
                  </button>
                  <button className="share--with--friends--app flex my-10" type="button" onClick={shareGuide}>
                    <FormattedMessage
                      id="whomatches.indication.button-share-with-friends"
                      defaultMessage="Compartilhar"
                    />
                  </button>
                </>
              ) : isIndicationLoading ? (
                <div
                  style={{
                    height: 150,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Spinner isLoading />
                </div>
              ) : (
                <>
                  <span className="guide--question py-6">
                    <FormattedMessage id="whomatches.description" />
                  </span>

                  <div className="relative flex flex-col">
                    <div className="relative">
                      <IndicationInput
                        placeholder="Com quem combina?"
                        disabled={!!selectedUser}
                        value={selectedUser ? selectedUser?.name : inputValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setSearchLoading(true);
                          setInputValue(e.target.value);
                        }}
                      />
                      {selectedUser && (
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            left: 280,
                            zIndex: 999,
                            bottom: 10,
                            color: '#8F8F8F'
                          }}
                        >
                          <button
                            type="button"
                            className="btn--remove-selected"
                            style={{
                              color: '#8F8F8F'
                            }}
                            onClick={() => {
                              setSelectedUser(undefined);
                              setInputValue('');
                            }}
                          >
                            <GrClose color="#8F8F8F" size={14} />
                          </button>
                        </div>
                      )}
                    </div>
                    {isSearchLoading && inputValue ? (
                      <ResultsBox>
                        <div
                          style={{
                            padding: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Spinner isLoading />
                        </div>
                      </ResultsBox>
                    ) : (
                      <>
                        {hasResults && !selectedUser ? (
                          <ResultsBox>
                            {searchResult.map((user: User) => (
                              <ResultContainer key={user.id} type="button" onClick={() => setSelectedUser(user)}>
                                <img src={user?.avatarUrl || fallbackUserImg} alt={user?.name} />
                                <span title={user?.name}>{user?.name}</span>
                              </ResultContainer>
                            ))}
                          </ResultsBox>
                        ) : null}
                        {noResults ? (
                          <ResultsBox>
                            <div
                              style={{
                                padding: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              <h1>
                                <FormattedMessage
                                  id="whomatches.errors.users-not-found"
                                  defaultMessage="Nenhum usuário encontrado"
                                />
                              </h1>
                            </div>
                          </ResultsBox>
                        ) : null}
                      </>
                    )}
                  </div>

                  <button
                    className="download--app mt-10"
                    disabled={!selectedUser}
                    type="button"
                    onClick={handleIndication}
                  >
                    <FormattedMessage id="whomatches.indication.button" defaultMessage="Indicar hit!" />
                  </button>

                  <button className="share--app flex my-10" type="button" onClick={shareGuide}>
                    <FormattedMessage id="whomatches.indication.button-share-guide" defaultMessage="Compartilhar" />
                  </button>
                </>
              )}
            </Container>
          )}
        </>
      )}
    </>
  );
};
