import Geocode from 'react-geocode';

import firebase from '../../firebaseConfig';

import { Indication, TopArtist } from '../../interfaces';
import removeDiacritics from '../../utils/removeDiacritics';
import replaceWhiteSpace from '../../utils/replaceWhiteSpace';

export const verifyIndication = async (compositionId: string, userProfileIdTo: string, userProfileIdFrom: string) => {
  const db = firebase.firestore();

  let currentTimestamp = new Date();

  currentTimestamp.setHours(0, 0, 0, 0);

  // Primeiro pega a hora atual do servidor (com um delay de 4 horas de atualização da function)
  const doc = await db.doc('configs/server').get();

  if (doc.exists) {
    currentTimestamp = doc.data()!.timestamp.toDate();
  }

  // Calcula a data de 0:00 de hoje
  const lastDay = new Date(currentTimestamp);

  // Agora faz a query para ver se já foi feita essa mesma indicação hoje
  const indicationCollection = db.collection(`compositions/${compositionId}/indications`);

  const indicationsInLessThan1Day = await indicationCollection
    .where('createdAt', '>', lastDay)
    .where('userProfileTo.id', '==', userProfileIdTo)
    .where('compositionId', '==', compositionId)
    .where('userProfileFrom.id', '==', userProfileIdFrom)
    .get();

  if (indicationsInLessThan1Day.docs.length) return true;
  return false;
};

export const getIndicationArtistCount = async (compositionId: string, artistId: string) => {
  const db = firebase.firestore();

  const doc = await db.collection(`compositions/${compositionId}/indicationArtist`).doc(artistId).get();

  if (doc.exists) {
    const ia = doc.data();
    return ia?.indicationsCount;
  }
  return 0;
};

export const getTopArtistsIndicatedByComposition = async (compositionId: string) => {
  const db = firebase.firestore();

  const data = await db
    .collection(`compositions/${compositionId}/indicationArtist`)
    .orderBy('indicationsCount', 'desc')
    .limit(5)
    .get();

  const tops: TopArtist[] = [];

  if (data.docs) {
    Object.keys(data.docs).forEach((key: any) => {
      const top = data.docs[key].data() as TopArtist;
      return tops.push(top);
    });
  }

  if (!data.empty) {
    return { success: true, data: tops };
  }

  return {
    success: false,
    code: 'undefined_error',
    message: 'undefined_error'
  };
};

const indicationService = async (indication: Indication) => {
  const db = firebase.firestore();

  return db
    .runTransaction(async (transaction) => {
      const compositionRef = db.collection('compositions').doc(indication.compositionId);

      const doc = await transaction.get(compositionRef);

      const composition = doc.data()!;

      // NOTE: Verify if the indication score match isn't null, if they have value we need to update composition data;

      if (indication.score!.percentTotalScore != 0) {
        for (let i = 0; i < indication.score!.matchs.length; i++) {
          const item = indication.score!.matchs[i];

          if (composition.indicationsScores) {
            // eslint-disable-next-line no-prototype-builtins
            if (composition.indicationsScores.hasOwnProperty(item)) {
              // NOTE -> Pega o objeto e se a key
              Object.keys(composition.indicationsScores).forEach((key) => {
                if (key === item) {
                  composition.indicationsScores[item] = ++composition.indicationsScores[item];
                }
              });
            } else {
              composition.indicationsScores[item] = 1;
            }
          } else {
            composition.indicationsScores = {};

            composition.indicationsScores[item] = 1;
          }
        }
      }

      // NOTE: Than we update the composition data;
      if (composition.indicationsScores) {
        transaction.set(
          compositionRef,
          {
            indicationsCount: firebase.firestore.FieldValue.increment(1),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            indicationsScores: composition.indicationsScores
          },
          { merge: true }
        );
      } else {
        transaction.set(
          compositionRef,
          {
            indicationsCount: firebase.firestore.FieldValue.increment(1),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          },
          { merge: true }
        );
      }

      const artistRef = db.collection('userProfiles').doc(indication.userProfileTo.id);

      transaction.set(
        artistRef,
        {
          indicationsCount: firebase.firestore.FieldValue.increment(1),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      );

      const indicationRef = db.collection(`compositions/${indication.compositionId}/indications`).doc();

      transaction.set(indicationRef, indication);

      const indicationArtistRef = db
        .collection(`compositions/${indication.compositionId}/indicationArtist`)
        .doc(indication.userProfileTo.id);

      transaction.set(
        indicationArtistRef,
        {
          compositionId: indication.compositionId,
          indicationsCount: firebase.firestore.FieldValue.increment(1),
          userProfile: indication.userProfileTo,
          updatedAt: indication.updatedAt,
          createdAt: indication.createdAt
        },
        {
          merge: true
        }
      );

      const indicationToRef = db
        .collection(`userProfiles/${indication.userProfileTo.id}/indicationsTo`)
        .doc(indication.compositionId);

      transaction.set(
        indicationToRef,
        {
          compositionId: indication.compositionId,
          indicationsCount: firebase.firestore.FieldValue.increment(1)
        },
        {
          merge: true
        }
      );

      if (
        indication.location != null &&
        indication.location.geolocation != null &&
        indication.location.geolocation.latitude != null &&
        indication.location.geolocation.longitude != null &&
        indication.location.geolocation.latitude != 0 &&
        indication.location.geolocation.longitude != 0
      ) {
        Geocode.setApiKey('AIzaSyCpTP-_eZjRthBwNPjxZE7o8m07w4qD6tg');
        Geocode.setLanguage('en');
        Geocode.enableDebug();
        await Geocode.fromLatLng(
          `${indication.location.geolocation.latitude}`,
          `${indication.location.geolocation.longitude}`
        ).then(
          async (response) => {
            for (let i = 0; i < response.results[0].address_components.length; i++) {
              for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                switch (response.results[0].address_components[i].types[j]) {
                  case 'administrative_area_level_2':
                    indication.location!.cityName = response.results[0].address_components[i].long_name;
                    break;
                  case 'administrative_area_level_1':
                    indication.location!.stateName = response.results[0].address_components[i].long_name;
                    break;
                  case 'country':
                    indication.location!.countryName = response.results[0].address_components[i].long_name;
                    indication.location!.countryCode = response.results[0].address_components[i].short_name;
                    break;
                  default:
                    break;
                }
              }
            }

            const cityLocation = indication.location!.cityName;
            const stateLocation = indication.location!.stateName;
            const countryLocation = indication.location!.countryName;

            let parsedCityLocation = `${cityLocation} ${stateLocation} ${countryLocation}`;
            let parsedStateLocation = `${stateLocation} ${countryLocation}`;

            const parsedCountryLocation = replaceWhiteSpace('_', countryLocation)!.toLowerCase();

            parsedCityLocation = replaceWhiteSpace('_', removeDiacritics(parsedCityLocation))!.toLowerCase();

            parsedStateLocation = replaceWhiteSpace('_', removeDiacritics(parsedStateLocation))!.toLowerCase();

            const indicationsCityRef = db
              .collection(`compositions/${indication.compositionId}/indicationsCity`)
              .doc(parsedCityLocation);

            transaction.set(
              indicationsCityRef,
              {
                cityName: indication.location!.cityName,
                countryCode: indication.location!.countryCode,
                countryName: indication.location!.countryName,
                stateName: indication.location!.stateName,
                indicationsCount: firebase.firestore.FieldValue.increment(1)
              },
              {
                merge: true
              }
            );

            const indicationsStateRef = db
              .collection(`compositions/${indication.compositionId}/indicationsState`)
              .doc(parsedStateLocation);

            transaction.set(
              indicationsStateRef,
              {
                countryCode: indication.location!.countryCode,
                countryName: indication.location!.countryName,
                stateName: indication.location!.stateName,
                indicationsCount: firebase.firestore.FieldValue.increment(1)
              },
              {
                merge: true
              }
            );

            const indicationsCountryRef = db
              .collection(`compositions/${indication.compositionId}/indicationsCountry`)
              .doc(parsedCountryLocation);

            transaction.set(
              indicationsCountryRef,
              {
                countryCode: indication.location!.countryCode,
                countryName: indication.location!.countryName,
                indicationsCount: firebase.firestore.FieldValue.increment(1)
              },
              {
                merge: true
              }
            );
          },
          (error) => {
            console.error(error);
          }
        );
      }
    })
    .then((value) => {
      return {
        success: true,
        code: '',
        message: ''
      };
    })
    .catch((error) => {
      console.log(error);

      return {
        success: false,
        code: 'undefined_error',
        message: 'undefined_error'
      };
    });
};

export default indicationService;
