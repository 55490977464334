import React from 'react';

import Router from './router';
import GlobalStyle from './globalStyle';

const App: React.FC = () => {
  let deferredPrompt: any;

  // mostrar prompt de instalar app
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
  });

  // Show the prompt
  deferredPrompt?.prompt();
  // Wait for the user to respond to the prompt
  deferredPrompt?.userChoice?.then((choiceResult: any) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
    } else {
      console.log('User dismissed the A2HS prompt');
    }
    deferredPrompt = null;
  });

  return (
    <>
      <Router />
      <GlobalStyle />
    </>
  );
};

export default App;
