import React from 'react';
import { FormattedMessage } from 'react-intl';

import mpLogo from '../images/logo-musicplayce-white.svg';
import avatar from '../images/avatar.svg';
import avatar2 from '../images/avatar2.svg';
import avatar3 from '../images/avatar3.svg';
import avatar4 from '../images/avatar4.svg';

import { Container } from '../poll/Pages/styles';

export const NotfoundPage: React.FC = () => {
  return (
    <Container>
      <img src={mpLogo} alt="Logo mp" />
      <h1 className="text-2xl text-white my-10">Página não encontrada</h1>
      <a className="download--app" href="http://link.musicplayce.com/baixar">
        <FormattedMessage id="poll.success.download-app" defaultMessage="Baixe o App" />
      </a>

      <span className="success--desc">
        <FormattedMessage id="poll.success.minimun-desc" defaultMessage="e participe da carreira de outros artistas" />
      </span>
      <div className="flex mt-4 items-center justify-around">
        <img src={avatar} alt="avatar" />
        <img src={avatar2} alt="avatar-2" />
        <img src={avatar3} alt="avatar-3" />
        <img src={avatar4} alt="avatar-4" />
      </div>
    </Container>
  );
};
