import styled from 'styled-components';

export const fallbackUserImg =
  'https://firebasestorage.googleapis.com/v0/b/musicplayce-prod.appspot.com/o/images%2Fdefault%2Favatar-cover.png?alt=media&token=08f9e277-e878-4625-b675-69b49e3a3f57';

type MusicBoxProps = {
  imgUrl?: string;
  isSmall?: boolean;
  isSelected?: boolean;
};

export const IndicationInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #8f8f8f;
  text-align: center;
  color: #fff;
  width: 100%;
  font-size: 1.2rem;
  max-width: 400px;
  padding: 10px;

  &::placeholder {
    color: #8f8f8f;
  }
`;

export const ResultsBox = styled.div`
  background-color: white;
  margin-top: 10px;
  border-radius: 8px;
  max-height: 180px;
  overflow-x: auto;
  box-shadow: 0px 2px 3px #333;
`;

export const ResultContainer = styled.button`
  display: flex;
  padding: 3px 10px;
  border-radius: 8px;
  align-items: center;
  width: 100%;
  transition: background-color 0.3s;

  img {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    margin: 10px 0;
  }

  span {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15rem;
  }

  &:hover {
    background-color: #ccc;
  }
`;

export const SelectedArtistPhoto = styled.div`
  width: 124px;
  height: 124px;
  margin-top: 48px;
  border-radius: 50%;
  border: 6px solid #504d4d;
  background-image: url(${(props: MusicBoxProps) => props.imgUrl || fallbackUserImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SmallUserPhoto = styled(SelectedArtistPhoto)`
  width: 42px;
  height: 40px;
  margin-top: 0;
  border: ${(props: MusicBoxProps) => (props.isSelected ? '3px solid #0D65FF' : '3px solid #504d4d')};
`;

export const ResultContainerStatic = styled.div`
  display: flex;
  padding: 3px 10px;
  border-radius: 8px;
  align-items: center;
  width: 100%;

  img {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    margin: 10px 0;
  }

  span {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15rem;
  }
`;
