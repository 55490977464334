import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  
  html,
  body {
  height: 100%;
  width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    color: #222;
    font-family: 'Inter', sans-serif;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
  }

  button {
    cursor: pointer;
    font-weight: 500;
  }

  input:focus,
  button:focus {
    outline: none;
    border-color: #cfcfcf;
  }

  @keyframes modal-wrapper {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

`;
